<template>
  <div class="charts">
    <Container
      class="charts__item"
      :preloader="preloader"
      style="overflow: hidden !important;"
    >
      <template #content>
        <div class="charts__item__content">
          <div class="charts__item__content-name">
            {{ $t('views.default.network.network.charts.partners-in-network') }}
          </div>

          <div class="charts__item__content__info">
            <h3>
              {{ value.agents }}
            </h3>

            <ArrowButton
              :text="$t('views.default.network.network.charts.invite-partners')"
              @click="$pop.up('invite')"
            />
          </div>
        </div>
      </template>
    </Container>

    <Container
      :preloader="preloader"
      class="charts__item"
      style="overflow: hidden !important;"
    >
      <template #content>
        <div class="charts__item__grid">
          <div class="charts__item__grid__content">
            <div class="charts__item__grid__content-name">
              {{ $t('views.default.network.network.charts.wholesale-turnover') }}
            </div>

            <div class="charts__item__grid__content__info">
              <div class="charts__item__grid__content__info__item">
                <div class="charts__item__grid__content__info__item-name">
                  {{ $t('views.default.network.network.charts.general') }}
                </div>

                <div class="charts__item__grid__content__info__item-value">
                  ${{ $format.wallet(value.network_turnover) }}
                </div>
              </div>

              <div class="charts__item__grid__content__info__item">
                <div class="charts__item__grid__content__info__item-name">
                  {{ $t('views.default.network.network.charts.this-week') }}
                </div>

                <div class="charts__item__grid__content__info__item-value">
                  ${{ $format.wallet(value.turnover_per_week) }}
                </div>
              </div>
            </div>
          </div>

          <div class="charts__item__grid__content">
            <div class="charts__item__grid__content-name green-color">
              {{ $t('views.default.network.network.charts.retail-turnover') }}
            </div>

            <div class="charts__item__grid__content__info">
              <div class="charts__item__grid__content__info__item">
                <div class="charts__item__grid__content__info__item-name">
                  {{ $t('views.default.network.network.charts.general') }}
                </div>

                <div class="charts__item__grid__content__info__item-value">
                  ${{ $format.wallet(value.network_turnover_products) }}
                </div>
              </div>

              <div class="charts__item__grid__content__info__item">
                <div class="charts__item__grid__content__info__item-name">
                  {{ $t('views.default.network.network.charts.this-week') }}
                </div>

                <div class="charts__item__grid__content__info__item-value">
                  ${{ $format.wallet(value.turnover_product_per_week) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Container>
  </div>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

export default {
  components: {
    Container,
    ArrowButton
  },
  data () {
    return {
      value: {},
      mountFetch: false,
      preloader: true
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const response = await this.$axios.get('private/widgets/network')

        if (response.status === 200) {
          this.value = response.data?.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 370px auto;
  gap: 20px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: auto;
  }

  &__item {
    padding: 10px;

    &__content {
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 45px;

      &-name {
        font-size: 14px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      &__info {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h3 {
          line-height: 100%;
          color: #202539;
          font-family: SemiBold;
        }

        &__invite {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 11px;
          line-height: 100%;
          text-align: right;
          text-transform: uppercase;
          color: #2F57E9;
          font-family: SemiBold;
          cursor: pointer;

          svg {
            height: 8px;
          }
        }
      }
    }

    &__grid {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: 768px) {
        grid-template-columns: auto;
        gap: 10px;
      }

      &__content {
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        &:first-child {
          margin-right: 10px;
          border-right: 1px solid rgba(156, 163, 184, .6);

          @media screen and (max-width: 768px) {
            margin-right: 0;
            padding-bottom: 20px;
            border-right: none;
            border-bottom: 1px solid rgba(156, 163, 184, .6);
          }
        }

        &-name {
          font-size: 14px;
          line-height: 100%;
          color: #2F57E9;
          font-family: SemiBold;

          &.green-color {
            color: rgb(0, 178, 7);
          }
        }

        &__info {
          width: 100%;
          display: flex;
          gap: 10px;

          &__item {
            width: 100%;

            &-name {
              font-size: 12px;
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
            }

            &-value {
              margin-top: 15px;
              font-size: 20px;
              line-height: 100%;
              color: #202539;
              font-family: SemiBold;
            }
          }
        }
      }
    }
  }
}
</style>
