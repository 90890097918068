<template>
  <div
    class="preloader-container"
    :class="{
      preloader: preloaderClass
    }"
  >
    <Preloader
      :visible="preloader"
      class="preloader-container__animate"
    />

    <slot name="content" />
  </div>
</template>

<script>
import Preloader from '@/ui/preloader/SkeletonAnimatePreloader.vue'

export default {
  components: {
    Preloader
  },
  props: {
    preloader: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      preloaderClass: true
    }
  },
  watch: {
    preloader () {
      this.preloaderClass = true

      setTimeout(() => {
        this.preloaderClass = false
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.preloader-container {
  position: relative;
  background: white;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  &.preloader {
    overflow: hidden !important;
  }

  &__animate {
    border-radius: inherit;
    overflow: hidden;
  }
}
</style>
