<template>
  <nav v-if="pages > 1">
    <div class="paginations">
      <div
        v-if="!button && name"
        class="links"
      >
        <template
          v-if="pages > 6"
        >
          <RouterLink
            v-for="i in _first"
            :key="i"
            :to="{
              name: name,
              params: Object.assign({}, $route.params, { page: i }),
              query: $route.query
            }"
            class="item"
            :class="{
              active : i == activePage
            }"
          >
            {{ i }}
          </RouterLink>

          <div class="line">
            ...
          </div>

          <template
            v-if="Number(activePage) >= 3 && (Number(activePage) + 1) < pages"
          >
            <template
              v-for="i in pages"
            >
              <RouterLink
                v-if="(i >= (activePage - 1) && i <= (Number(activePage) + 1))"
                :key="i"
                :to="{
                  name: name,
                  params: Object.assign({}, $route.params, { page: i }),
                  query: $route.query
                }"
                class="item"
                :class="{
                  active : i == activePage
                }"
              >
                {{ i }}
              </RouterLink>
            </template>

            <div class="line">
              ...
            </div>
          </template>

          <template
            v-for="i in pages"
          >
            <RouterLink
              v-if="(i > (pages - 3) && Number(activePage) >= (pages - 1) || i == pages || Number(activePage) < 3 && i >= (pages - 2))"
              :key="i"
              :to="{
                name: name,
                params: Object.assign({}, $route.params, { page: i }),
                query: $route.query
              }"
              class="item"
              :class="{
                active : i == activePage
              }"
            >
              {{ i }}
            </RouterLink>
          </template>
        </template>

        <template
          v-else
        >
          <RouterLink
            v-for="i in pages"
            :key="i"
            :to="{
              name: name,
              params: Object.assign({}, $route.params, { page: i }),
              query: $route.query
            }"
            class="item"
            :class="{
              active : i == activePage
            }"
          >
            {{ i }}
          </RouterLink>
        </template>
      </div>

      <div
        v-else
        class="buttons"
      >
        <template
          v-if="pages > 6"
        >
          <button
            v-for="i in _first"
            :key="i"
            class="item"
            :class="{
              active : i == activePage
            }"
            @click="$emit('page', i)"
          >
            {{ i }}
          </button>

          <div class="line">
            ...
          </div>

          <template
            v-if="Number(activePage) >= 3 && (Number(activePage) + 1) < pages"
          >
            <template
              v-for="i in pages"
            >
              <button
                v-if="(i >= (activePage - 1) && i <= (Number(activePage) + 1))"
                :key="i"
                class="item"
                :class="{
                  active : i == activePage
                }"
                @click="$emit('page', i)"
              >
                {{ i }}
              </button>
            </template>

            <div class="line">
              ...
            </div>
          </template>

          <template
            v-for="i in pages"
          >
            <button
              v-if="(i > (pages - 3) && Number(activePage) >= (pages - 1) || i == pages || Number(activePage) < 3 && i >= (pages - 2))"
              :key="i"
              class="item"
              :class="{
                active : i == activePage
              }"
              @click="$emit('page', i)"
            >
              {{ i }}
            </button>
          </template>
        </template>

        <template
          v-else
        >
          <button
            v-for="i in pages"
            :key="i"
            class="item"
            :class="{
              active : i == activePage
            }"
            @click="$emit('page', i)"
          >
            {{ i }}
          </button>
        </template>
      </div>
    </div>

    <LightButton
      v-if="loadMore"
      class="loading-button"
      style="max-width: 250px;"
      :text="$t('components.ui.pagination.show-more')"
      to-bottom
      loaded
      :loading="loading"
      :disabled="loading"
      @click="$emit('loadMore')"
    />
  </nav>
</template>

<script>
import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    LightButton
  },
  props: {
    name: {
      type: String,
      default: null
    },
    pages: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      default: null
    },
    loadMore: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activePage () {
      return this.page && this.button ? this.page : this.$route.params.page ? this.$route.params.page : 1
    },
    _first () {
      if (this.activePage < 3) {
        return 3
      } else if (Number(this.activePage) >= (this.pages - 1)) {
        return 3
      } else {
        return 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .paginations {
    height: auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: rgb(var(--color-dark));

    .links, .buttons {
      display: flex;
      align-items: center;

      .item {
        height: 36px;
        width: 36px;
        margin-right: 10px;
        padding-top: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(var(--primary-color), .2);
        border-radius: 50%;
        color: rgba(var(--color-dark), 1);
        font-weight: 600;
        font-size: 14px;
        font-family: SemiBold;
        line-height: 100%;
        text-decoration: none;
        cursor: pointer;
        transition: .3s;

        &:hover {
          background: rgba(87, 127, 255, 1);
          color: white;
        }

        &.active {
          background: rgba(88, 107, 177, 1);
          color: white;
        }

        &:last-child {
          margin: 0;
        }
      }

      .line {
        width: 16px;
        margin: 0 10px 0 0;
        display: flex;
        justify-content: center;
        color: rgb(var(--color-dark));
        font-size: 14px;
        font-family: SemiBold;
        cursor: default;
      }
    }
  }

  button.load-more {
    padding: 12px 55px;
    background: transparent;
    border: 1px solid rgba(var(--sub-light-blue-color), .4);
    border-radius: 4px;
    font-size: 15px;
    line-height: 100%;
    color: rgba(var(--sub-light-blue-color), 1);
  }
}

@media screen and (max-width: 768px) {
  nav {
    align-items: center;
    flex-direction: column;

    button.loading-button {
      max-width: 100% !important;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 340px) {
  nav {
    .paginations {
      .links, .buttons {
        .item {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
