<template
  v-if="value.length"
>
  <h3>
    {{ $t('views.default.network.network.partner-network') }}
  </h3>

  <div class="tab__list">
    <div
      class="tab__list__grid"
      :style="{
        'grid-template-columns': `repeat(${_columns}, 1fr)`
      }"
    >
      <div
        v-for="item in value"
        :key="item.id"
        class="tab__list__grid__item"
      >
        <div class="tab__list__grid__item__top">
          <div class="tab__list__grid__item__top__labels">
            <div
              class="tab__list__grid__item__top__labels__status small-text"
              :style="{
                background: item.rank_color
              }"
            >
              {{ item.rank_name }}
            </div>

            <div class="tab__list__grid__item__top__labels__id">
              ID {{ item.id }}
            </div>
          </div>

          <p style="margin-top: 20px;">
            {{ item.firstname }} {{ item.surname }}
          </p>

          <p>
            +{{ item.phone }}
          </p>
        </div>

        <div class="tab__list__grid__item__content">
          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.wholesale-turnover') }}

            <p style="white-space: nowrap;">
              ${{ $format.wallet(item.turnover_network) }}
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.retail-turnover') }}

            <p style="white-space: nowrap;">
              ${{ $format.wallet(item.turnover_network_products) }}
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.participants') }}

            <p style="white-space: nowrap;">
              {{ item.network_size }}
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.wholesale-bonus-1-lines') }}

            <p style="white-space: nowrap;">
              {{ item.percent_firstline }}%
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.wholesale-bonus-2-lines') }}

            <p style="white-space: nowrap;">
              {{ item.percent }}%
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.line-1-retail-bonus') }}

            <p style="white-space: nowrap;">
              {{ item.percent_products_firstline }}%
            </p>
          </div>

          <div class="tab__list__grid__item__content__line">
            {{ $t('views.default.network.network.list.line-2-retail-bonus') }}

            <p style="white-space: nowrap;">
              {{ item.percent_products }}%
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="tab__nav">
      <Pagination
        :name="$route.name"
        :pages="_countItemPagination"
        :load-more="value.length < count"
        :loading="isFetchMore"
        @loadMore="fetchMore()"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      value: [],
      mountFetch: false,
      isFetchMore: false,
      preloader: true,
      count: 0,
      length: 5
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.length = this.$store.state.window.width <= 1570 ? 6 : 5

    this.fetch()
  },
  deactivated () {
    if (this.value.length > (this.length * 2)) {
      this.value = this.value.slice(0, (this.length * 2) + 1)
    }
  },
  computed: {
    _columns () {
      return this.$store.state.window.to768 ? 1
        : this.$store.state.window.width <= 1024 ? 2
          : this.$store.state.window.width <= 1280 ? 3
            : this.$store.state.window.width <= 1570 ? 4
              : this.length
    },
    _offset () {
      return ((this.$route.params.page ? this.$route.params.page : 1) * (this.length * 2)) - (this.length * 2)
    },
    _countItemPagination () {
      if (this.count) {
        return Math.ceil(this.count / (this.length * 2))
      }

      return 0
    }
  },
  methods: {
    async fetch () {
      try {
        const response = await this.$axios.get(
          `/private/network?limit=${this.length * 2}&offset=${this._offset}`)
        if (response.status === 200) {
          this.count = response.data.count
          this.value = response.data.data

          if (this.$route.params.page > 1 && this.value.length === 0) {
            this.$store.commit('SET_ERROR', true)
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this._offset + (this.value.length ? this.value.length : 0)
      const link = `/private/network?offset=${offset}&limit=${this.length * 2}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.value.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      if (this.$route.params.page) {
        this.fetch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 60px;
  color: rgba(var(--color-dark));
  line-height: 100%;
  font-family: SemiBold;
}

.tab__list {
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }

  .tab__list__grid {
    display: grid;
    // grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
    }

    .tab__list__grid__item {
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
      border-radius: 15px;

      .tab__list__grid__item__top {
        padding: 15px 15px 20px 15px;
        background: rgba(240, 242, 248, .5);
        border-radius: 15px;

        .tab__list__grid__item__top__labels {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tab__list__grid__item__top__labels__status {
            padding: 5px 10px;
            border-radius: 20px;
            color: white;
            line-height: 100%;
            font-family: Medium;
          }

          .tab__list__grid__item__top__labels__id {
            color: rgba(156, 163, 184, 1);
            font-size: 12px;
            font-family: Medium;
          }
        }

        p {
          margin-top: 10px;
          color: rgb(var(--color-dark));
          line-height: 100%;
          font-size: 18px;
          font-family: SemiBold;
        }
      }

      .tab__list__grid__item__content {
        padding: 20px 15px;

        .tab__list__grid__item__content__line {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: rgb(120, 124, 141);
          line-height: 100%;
          font-size: 12px;
          font-family: Medium;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            color: rgb(var(--color-dark));
            font-size: 16px;
            font-family: SemiBold;
          }
        }
      }
    }
  }
}

.tab__nav {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
