<template>
  <div class="network">
    <Chart />

    <List />
  </div>
</template>

<script>
import Chart from '@/modules/network-and-rank/network/Chart.vue'
import List from '@/modules/network-and-rank/network/List.vue'

export default {
  components: {
    Chart, List
  }
}
</script>

<style lang="scss" scoped>
.network {
  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }
}
</style>
